import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"


const Header = ({ siteTitle }) => (

 
  <header
    style={{
      background: `#000000`
    }}
  >
   
  

 </header>
 
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  headerButton: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  headerButton: `https://pawsomecouture.com`,
}


export default Header
