/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      { /* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */ }

    
    
        <main>{children}</main>
        <footer>      
          <a class="official" href="https://www.pawsomecouture.com">© 2022, Pawsome Couture® </a>
          <a class="footer_links" href="https://www.pawsomecouture.com/pages/privacy-policy">Privacy Policy -  </a>
          <a class="footer_links" href="https://www.pawsomecouture.com/pages/terms-conditions"> Terms and Conditions </a>
        </footer>
     
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
